.bold {
  font-weight: bold !important;
}

.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.fw-300 {
  font-weight: 300;
}

.fw-200 {
  font-weight: 200;
}

.text-center {
  text-align: center !important;
}

.mb-10 {
  margin-bottom: 10px;
}
.mb-12 {
  margin-bottom: 12px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.text-left {
  text-align: left;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mtb-6 {
  margin: 6px 0;
}

.mtb-12 {
  margin: 12px 0;
}

.mt-12-b-24 {
  margin-top: 12px;
  margin-bottom: 24px;
}

.danger {
  color: #e74c3c;
}
.success {
  color: #2ecc71;
}
.link-color {
  color: rgb(15, 139, 225);
}
.orange {
  color: #f39c12;
}

.purple {
  color: #9b59b6;
}

.silver {
  color: #bdc3c7;
}

.color-primary {
  color: #1e84be;
}

.color-text {
  color: #646668;
}

.color-black {
  color: var(--color-black);
}

.flex {
  display: flex;
}
.aic {
  align-items: center;
}

.link {
  color: var(--color-primary);
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  // word-break: break-all;
}

.text-center {
  text-align: center;
}

.wide {
  width: 100%;
}

.underline {
  text-decoration: underline;
}
